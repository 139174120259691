export function Logo(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      fill="none"
      viewBox="0 0 512 512"
      {...props}
    >
      <g clipPath="url(#clip0_303_27)">
        <path
          fill="#2463EB"
          d="M384 0H128C57.308 0 0 57.307 0 128v256c0 70.692 57.307 128 128 128h256c70.692 0 128-57.308 128-128V128C512 57.308 454.692 0 384 0z"
        ></path>
        <g clipPath="url(#clip1_303_27)">
          <path
            fill="#fff"
            d="M395.538 122.524c-3.121-3.175-7.354-5.184-11.48-6.511a67.553 67.553 0 00-14.942-2.691c-11.121-.879-25.005 0-40.395 3.139-30.799 6.314-68.485 21.92-103.446 52.145-34.978 30.242-56.826 66.799-67.409 97.275-5.273 15.193-7.892 29.309-7.695 40.682.09 5.668.897 11.121 2.762 15.785.305.717.628 1.471 1.005 2.188a1225.594 1225.594 0 00-40.503 61.077 9.47 9.47 0 002.816 12.915 9.043 9.043 0 0012.646-2.87 1213.154 1213.154 0 0138.709-58.476c5.112 2.529 11.05 3.498 17.184 3.265 9.202-.359 19.696-3.373 30.907-8.79 22.422-10.852 49.22-32.054 77.185-65.328l27.229-27.803a9.472 9.472 0 000-13.256l-13.077-13.345 23.517 3.749a9.048 9.048 0 007.946-2.691 2674.05 2674.05 0 0111.892-12.269c11.893-12.198 23.176-23.768 31.624-33.938 5.633-6.78 10.494-13.489 13.525-19.857 2.924-6.188 4.987-13.866 2.009-21.31a9.404 9.404 0 00-2.009-3.085zM178.961 321.433c29.632-40.288 62.907-79.015 100.019-111.679l21.705 22.135-21.005 21.453a9.89 9.89 0 00-.485.538c-26.906 32.091-51.857 51.427-71.337 60.862-9.758 4.718-17.758 6.781-23.749 6.996a22.991 22.991 0 01-5.148-.305zM300.38 191.924c23.498-18.439 48.431-34.332 74.835-46.637a122.808 122.808 0 01-7.175 9.381c-7.928 9.561-18.44 20.341-30.153 32.359l-9.148 9.4-28.359-4.503zm60.019-60.216c-76.307 37.741-139.967 103.266-191.914 172.828.126-8.323 2.171-19.48 6.637-32.395 9.561-27.534 29.597-61.257 61.974-89.257 32.413-28.001 67.194-42.333 95.248-48.073a143.027 143.027 0 0128.055-3.103z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_303_27">
          <path fill="#fff" d="M0 0H512V512H0z"></path>
        </clipPath>
        <clipPath id="clip1_303_27">
          <path
            fill="#fff"
            d="M0 0H287V287H0z"
            transform="translate(112 113)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}
